 .left-menu{
    position: absolute;
 }
 
 .p-card, .p-fieldset{
    background: #0c96ff44 !important;
    border-radius: 10px !important
}

.p-card .p-card-title{
    min-height: 96px;
}

.eco-system{
    margin: 0 0 25px 0;
    z-index: 99999;
    /* cursor: pointer; */
    letter-spacing: 0.15em;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

/* .eco-system:hover{
    scale: 1.01;
    filter: drop-shadow(0px 2px 5px black);
}

.eco-system:active{
    scale: 0.999;
} */

.card-frame{
    width: 100%;
    display: flex;
    height: 70%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.card-frame-1-row{
    display: flex;
    justify-content: flex-start;
    height: auto;
    width: 50%;
    margin-left: 10%;
}

.card-frame-2-row{
    display: flex;
    justify-content: flex-end;
    height: auto;
    width: 50%;
    margin-right: 10%;
}

img{
    width: 400px;
    height: 223px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

a{
    outline: none;
    text-decoration: none;
}

.button-link{
    cursor: pointer;
    color: #204080;
    background-color: #ffffff;
    height: 40px;
    border-radius: 7px;
}

.button-link:hover{
    scale: 1.01;
    filter: drop-shadow(0px 0px 5px #ffffff);
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.button-link:active{
    scale: 0.999;
}